import {Avatar, Box, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailForm from "./components/email-form";
import {useState} from "react";
import CodeForm from "./components/code-form";
import {sendAuthCode} from "../../services/api";
import useAuth from "../../hooks/use-auth";
import {TooManyRequestsError} from "../../errors";
import {Navigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SignInPage = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [hasSentCode, setHasSentCode] = useState(false);
    const [email, setEmail] = useState(null);
    const [errorSendingCode, setErrorSendingCode] = useState(null);
    const [errorSigningIn, setErrorSigningIn] = useState(null);
    const { user, signIn } = useAuth();

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        setErrorSigningIn(null);
        setErrorSendingCode(null);
        const data = new FormData(event.currentTarget);
        try {
            await sendAuthCode(data.get('email'));
            setEmail(data.get('email'));
            setHasSentCode(true);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                setErrorSendingCode(t("sign-in-page.send-code-429-error-message"));
            } else {
                setErrorSendingCode(t("sign-in-page.send-code-default-error-message"));
            }
        }
    }

    const handleCodeSubmit = async (event) => {
        event.preventDefault();
        setErrorSigningIn(null);
        const data = new FormData(event.currentTarget);
        const authCode = data.get('auth-code')
        try {
            await signIn(email, authCode);
        } catch (error) {
            if (error instanceof TooManyRequestsError) {
                setErrorSigningIn(t("sign-in-page.send-code-429-error-message"));
            } else {
                setErrorSigningIn(t("sign-in-page.default-error-message"));
            }
        }
    }

    const goBack = () => {
        setHasSentCode(false);
    }
// Navigate back to where the user where or to the root page

    const singInPageContent = (
        <Grid container component="main" sx={{height: "100vh"}}>
            <Grid xs={false} sm={4} md={7} sx={{
                backgroundImage: "url(https://source.unsplash.com/random)",
                backgroundRepeat: "no-repeat",
                backgroundColor: (theme) => theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>
            <Grid xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("sign-in-page.sign-in")}
                    </Typography>
                    {
                        hasSentCode
                            ?
                            <CodeForm
                                handleSubmit={handleCodeSubmit}
                                back={goBack}
                                errorText={errorSigningIn}
                                email={email}
                            />
                            :
                            <EmailForm
                                handleSubmit={handleEmailSubmit}
                                errorText={errorSendingCode}
                            />
                    }
                </Box>
            </Grid>
        </Grid>
    );

    // If the user is already signed in, redirect them to the page they were trying to access or to the root page
    const to = location.state?.from?.pathname || "/";
    return user ? <Navigate to={to} replace /> : singInPageContent;
}

export default SignInPage;
