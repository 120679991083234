import {
    Box, IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {format} from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Unstable_Grid2";


const TimeTable = ({ isPending, startBooking, bookings, onBookingDelete, user }) => {

    const hours = Array.from({length: 24}, (_, i) => {
        if (i < 10) {
            return `0${i}:00`
        } else {
            return `${i}:00`
        }
    });

    const onClick = (event) => {
        startBooking(event.target.parentElement.firstChild.innerText);
    }

    if (isPending) return <Skeleton variant="rectangle" width="100%" height="100vh"/>;

    return (
        <div style={{position: "relative"}}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {hours.map(hour => (
                            <TableRow key={hour}>
                                <TableCell sx={{paddingTop: 0, width: "50px"}}>{hour}</TableCell>
                                <TableCell
                                    sx={{height: "50px", '&:hover': {cursor: 'pointer'}}}
                                    onClick={onClick}
                                >
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{
                position: "absolute",
                zIndex: 10,
                top: 0,
                left: "70px",
                width: "calc(100% - 70px)",
            }}>
                {bookings?.map(booking => (
                    <Box key={booking.id} sx={(theme) => ({
                        position: "absolute",
                        top: `${(booking?.startTime.getHours() + booking?.startTime.getMinutes() / 60) * 50}px`,
                        left: 0,
                        width: "100%",
                        height: `${(booking?.endTime - booking?.startTime) / 1000 / 60 / 60 * 50}px`,
                        backgroundColor: theme.palette.secondary.main,
                        opacity: 0.5,
                    })}>
                        <Grid container>
                            <Grid xs={10}>
                                <Typography variant="body1" p={1}>
                                    {booking.bookerName}: {format(booking.startTime, "HH:mm")} - {format(booking.endTime, "HH:mm")}
                                </Typography>
                            </Grid>
                            <Grid xs={2} display="flex" justifyContent="center">
                                {
                                    (user.id === booking.bookerId) ?
                                        <IconButton onClick={() => onBookingDelete(booking)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </div>
        </div>
    )
}

export default TimeTable;
