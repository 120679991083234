import axios from 'axios';
import {BASE_URL} from "../constants";
import {formatISO} from "date-fns";

export const sendAuthCode = async (email) => {
    return await axios.get(`${BASE_URL}/authentication/code/${email}`);
}

export const getJwt = async (email, code) => {
    return await axios.post(`${BASE_URL}/authentication`, { email, code });
}

export const getRooms = async (jwt) => {
    return await axios.get(
        `${BASE_URL}/rooms`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    );
}

export const getBookings = async (jwt, start_date, end_date) => {
    return await axios.get(
        `${BASE_URL}/bookings`,
        {
            headers: { Authorization: `Bearer ${jwt}` },
            params: {
                start_time: formatISO(start_date),
                end_time: formatISO(end_date)
            }
        }
    );
}

export const createBooking = async (jwt, room_id, start_time, end_time) => {
    return await axios.post(
        `${BASE_URL}/bookings`,
        {
            room_id,
            start_time: formatISO(start_time),
            end_time: formatISO(end_time)
        },
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    )
}

export const deleteBooking = async (jwt, booking_id) => {
    return await axios.delete(
        `${BASE_URL}/bookings/${booking_id}`,
        {
            headers: { Authorization: `Bearer ${jwt}` }
        }
    )
}
