import {createTheme} from "@mui/material";

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: "#646c5d",
			light: "#aaaa8a"
		},
		secondary: {
			main: "#ff7b26",
			dark: "#ff7b26"
		},
		error: {
			main: "#ff5722"
		},
		warning: {
			main: "#ff5722"
		}
	},

	typography: {

	}


});
