const translations = {
    "sign-in-page": {
        "sign-in": "Logga in",
        "default-error-message": "Ett fel inträffade vid inloggning. Koden kan endast användas en gång och endast den senaste koden är aktiv.",
        "send-code-default-error-message": "Ett fel inträffade vid skickandet av koden. Vänligen försök igen senare.",
        "send-code-429-error-message": "Du har redan begärt en kod och måste vänta lite innan du kan begära en ny kod.",
    },
    "email-form": {
        "email-label": "Email Adress",
        "submit-button": "Logga in"
    },
    "code-form": {
        "code-label": "Autentiseringskod",
        "try-another-email": "Försök med en annan email",
        "submit-button": "Logga in",
        "code-sent-message": "Vi har skickat en kod till {{email}}",
    },
    "book-form": {
        "title": "Boka {{roomName}}",
        "start-time-label": "Start",
        "end-time-label": "Slut",
        "submit-button": "Boka",
        "cancel-button": "Avbryt"
    },
    "date-picker": {
        "label": "Datum",
        "today": "Idag"
    },
    "room-picker": {
        "label": "Rum"
    },
    "booking-page": {
        "delete-form": {
            "title": "Radera bokning",
            "cancel-button": "Avbryt",
            "delete-button": "Radera"
        }
    }
}

export default translations;
