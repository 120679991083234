import {Outlet, Route, Routes} from "react-router-dom"
import SignInPage from "./containers/sing-in-page/sign-in-page";
import {AuthProvider} from "./contexts/auth-provider";
import BookingPage from "./containers/booking-page/booking-page";
import RequireAuth from "./components/require-auth";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./i18n";

const queryClient = new QueryClient();

function App() {
    return (
      <AuthProvider>
          <QueryClientProvider client={queryClient}>
              <div className="app">
                  <Routes>
                      <Route path={"/"} element={<RequireAuth><BookingPage /></RequireAuth>}/>
                      <Route path={"/sign-in"} element={<SignInPage/>}/>
                      <Route path={"*"} element={<RequireAuth><h1>Not Found</h1></RequireAuth>}/>
                  </Routes>
                  <Outlet/>
              </div>
          </QueryClientProvider>
      </AuthProvider>
    );
}

export default App;
