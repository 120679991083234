import React, {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import AuthContext from './auth-context';
import {getJwt} from "../services/api";
import {TooManyRequestsError} from "../errors";

const TOKEN_KEY = 'authToken';

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: null,
        user: null,
        exp: null,
    });

    useEffect(() => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
          try {
              const { user_id: userId, exp } = jwtDecode(token);
              const now = Date.now().valueOf() / 1000;
              if (exp < now) {
                  console.warn('Token expired');
                  signOut();
              } else {
                  setAuthState({
                      token,
                      user: { id: userId },
                      exp,
                  });
              }
          } catch (error) {
              console.error('Failed to parse token', error);
              localStorage.removeItem(TOKEN_KEY);
          }
      }
    }, [])

    const signIn = async (email, authCode) => {
        const response = await getJwt(email, authCode);
        if (response.status === 429) {
            throw new TooManyRequestsError();
        } else if (response.status !== 200) {
            throw new Error('Failed to sign in');
        }
        const token = response.data.jwt;
        const {user_id: userId, exp} = jwtDecode(token);

        setAuthState({
            token,
            user: {id: userId},
            exp,
        });

        // Save the token to local storage or cookies if you want to persist the login
        localStorage.setItem(TOKEN_KEY, token);

        return authState.user;
    };

    const signOut = () => {
        setAuthState({
            token: null,
            user: null,
        });

        localStorage.removeItem(TOKEN_KEY);
    };

    return (
        <AuthContext.Provider value={{ ...authState, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};
