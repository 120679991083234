import useAuth from "../hooks/use-auth";
import {AppBar, IconButton, Link, Toolbar, Typography} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';


const AppHeader = () => {
    const {signOut, user} = useAuth();

    return (
        <AppBar position="static">
            <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link href="/" sx={
                            (theme) => {return {
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                    textDecoration: 'none',
                                    color: theme.palette.primary.contrastText
                                }
                            }}}
                        >
                            CoworkingNavet
                        </Link>
                    </Typography>
                {user && (
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={signOut}
                            color="inherit"
                        >
                            <LogoutIcon />
                        </IconButton>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );

}

export default AppHeader;
